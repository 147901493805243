import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const FooterMenusWidgets = ({}) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        getFooter {
          copyrightText
          sidebarOne
          sidebarTwo
        }
      }
    }
  `)
  return (
    <div className="footer-nav-widgets-wrapper header-footer-group">
      <div className="footer-inner section-inner">
        <aside className="footer-widgets-outer-wrapper" role="complementary">
          <div className="footer-widgets-wrapper">
            <div
              className="footer-widgets column-one grid-item"
              dangerouslySetInnerHTML={{ __html: wp.getFooter.sidebarOne }}
            />
            <div
              className="footer-widgets column-two grid-item"
              dangerouslySetInnerHTML={{ __html: wp.getFooter.sidebarTwo }}
            />
          </div>
        </aside>
      </div>
    </div>
  )
}

export default FooterMenusWidgets
